const KEYS = {
  user: "user",
};

export const storage = {
  user: {
    get: function () {
      return readFromLocalStorage({ key: KEYS.user });
    },
    set: function (value) {
      return writeToLocalStorage({ key: KEYS.user, value });
    },
  },
};

export const writeToLocalStorage = ({ key, value }) => {
  if (value == null) {
    localStorage.removeItem(storageKey(key));
  }
  localStorage.setItem(storageKey(key), JSON.stringify(value));
};

export const readFromLocalStorage = ({ key }) => {
  let res;
  try {
    res = JSON.parse(localStorage.getItem(storageKey(key)));
  } catch (e) {
    res = localStorage.getItem(storageKey(key));
  }
  return res;
};

export const writeToSession = (key, value) => {
  if (value == null) {
    sessionStorage.removeItem(storageKey(key));
  }
  return sessionStorage.setItem(storageKey(key), JSON.stringify(value));
};

export const readFromSession = (key) => {
  let res;
  try {
    res = JSON.parse(sessionStorage.getItem(storageKey(key)));
  } catch (e) {
    res = sessionStorage.getItem(storageKey(key));
  }
  return res;
};

const storageKey = (key) => {
  return `${process.env.REACT_APP_STORAGE_PREFIX}:${key}`;
};
