import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import CookieBot from "react-cookiebot";
import TagManager from "react-gtm-module";

const MockAnalyticsProvider = {
  event: function (payload) {
    console.log(`analytics event: ${JSON.stringify(payload)}`);
  },
  initialize: function (analyticsId) {
    console.log("Initialized mock analytics provider");
  },
  pageview: function (path) {
    console.log(`Logging pageview at ${path}`);
  },
};

const initAnalytics = () => {
  TagManager.initialize({ gtmId: "GTM-TSM29C7N" });
};

const initPageTracker = () => {
  return MockAnalyticsProvider;
};

export const AnalyticsProvider = initPageTracker();
export const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    AnalyticsProvider.pageview(location.pathname + location.search);
  }, [location]);
};

const Trackers = () => {
  initAnalytics();
};

export const WithTracking = () => {
  return (
    <div>
      <CookieBot domainGroupId={process.env.REACT_APP_COOKIEBOT_ID} />
      <Trackers />
    </div>
  );
};
