import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { doubleSpace } from "components/sharedComponents";

import { AuthContext } from "components/AuthContext";

import { EmailCapture } from "components/EmailCapture";

export const SplashPage = (props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/shift-dashboard");
  }, [navigate, user]);

  return (
    <StyledHomePage>
      <EmailCapture headline={"Join the Beta"} category="homecare-0.1" />
    </StyledHomePage>
  );
};

const StyledHomePage = styled.div`
  padding: ${doubleSpace} 0;
`;
