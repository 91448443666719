import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "components/AuthContext";

export const Logout = () => {
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    logout();
  }, [logout]);

  return <Navigate to="/" />;
};
