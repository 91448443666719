import React from "react";
import styled from "styled-components";

import {
  ActionButton,
  ErrorMessage,
  Paragraph,
  green,
  lightBlueOpaque,
  mediumGray,
  singleSpace,
  doubleSpace,
  quadSpace,
} from "components/sharedComponents";
import { formatDateTime } from "utils/dateTime";

const CONFIRMATION_ACTIONS = {
  accept: "accept",
  decline: "decline",
};

export const ShiftConfirmationCard = ({
  confirmation,
  error,
  loading,
  submit,
  submittedAction,
}) => {
  if (!submittedAction) {
    return (
      <ShiftDetails
        confirmation={confirmation}
        error={error}
        loading={loading}
        submit={submit}
      />
    );
  } else {
    return <ActionConfirmation submittedAction={submittedAction} />;
  }
};

const ActionConfirmation = ({ submittedAction }) => {
  if (submittedAction === CONFIRMATION_ACTIONS.accept) {
    return (
      <StyledActionCard>
        <Paragraph>
          Great thank you! You just earned 200 reward points!
        </Paragraph>
      </StyledActionCard>
    );
  }
  if (submittedAction === CONFIRMATION_ACTIONS.decline) {
    return (
      <StyledActionCard>
        <Paragraph>
          Sorry to hear it! I'll let the staffing team know to find a
          replacement caregiver.
        </Paragraph>
      </StyledActionCard>
    );
  }
};

const ShiftDetails = ({ confirmation, error, loading, submit }) => {
  const { shift } = confirmation;
  const { recipient } = shift;
  const { month, day, hours, minutes, period } = formatDateTime(
    confirmation.shift.start_date,
  );
  const {
    hours: endHours,
    minutes: endMinutes,
    period: endPeriod,
  } = formatDateTime(confirmation.shift.end_date);

  return (
    <StyledActionCard>
      <Paragraph className="title">Shift Details</Paragraph>
      <ul>
        <li>
          <Paragraph>
            {recipient.first_name} {recipient.last_name}
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            {month} {day}
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            {hours}:{minutes} {period} - {endHours}:{endMinutes} {endPeriod}
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            {recipient.street_address}, {recipient.city}
          </Paragraph>
        </li>
      </ul>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <ActionButton
        className="accept"
        disabled={loading}
        onClick={submit(CONFIRMATION_ACTIONS.accept)}
        text={loading ? "Submitting..." : "Yes I'll be there!"}
      />
      <ActionButton
        className="decline"
        disabled={loading}
        onClick={submit(CONFIRMATION_ACTIONS.decline)}
        text={loading ? "Submitting..." : "Cancel"}
      />
    </StyledActionCard>
  );
};

const StyledActionCard = styled.div`
  padding: ${quadSpace} ${doubleSpace};
  border-radius: 20px;
  background-color: ${lightBlueOpaque};
  margin-bottom: ${doubleSpace};

  ul {
    margin-bottom: ${quadSpace};
    li p {
      font-weight: 500;
    }
  }

  p.title {
    font-weight: 700;
  }

  button {
    display: block;
    width: 100%;
    margin-bottom: ${singleSpace};
    padding: ${singleSpace};

    &.accept {
      color: white;
      background-color: ${green};
    }
    &.decline {
      color: black;
      background-color: ${mediumGray};
    }
  }
`;
