import React, { createContext, useEffect, useState } from "react";

import { httpClient } from "./HttpClient";
import { storage } from "services/storage";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authError, setAuthError] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const localUser = storage.user.get();

      if (localUser && localUser.token) {
        let res;
        try {
          res = await httpClient.get("users/auth", {
            headers: {
              Authorization: `Token ${localUser?.token}`,
            },
          });
        } catch (err) {
          setAuthError(err);
        }

        if (res?.status && res.status !== 200) {
          logout();
        } else {
          setUser(localUser);
          setLoggedIn(true);
        }
      }

      setLoadingAuth(false);
    };
    fetchUser();
  }, []);

  const login = (user) => {
    setUser(user);
    storage.user.set(user);
    setLoggedIn(true);
  };

  const logout = () => {
    setUser(null);
    storage.user.set(null);
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{ authError, loadingAuth, loggedIn, login, logout, user }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
