import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  lightGray,
  doubleSpace,
  Paragraph,
  md,
  Subtitle,
  tripleSpace,
  quadSpace,
} from "components/sharedComponents";

export const WaitlistConfirmation = () => {
  return (
    <StyledConfirmation>
      <Container>
        <Row>
          <Col lg={{ offset: 3, span: 6 }}>
            <StyledMessage>
              <Subtitle>Success!</Subtitle>
              <Paragraph>
                We received your request, and our team has been notified. If
                you're a fit for the beta program we'll reach out soon.
              </Paragraph>
            </StyledMessage>
          </Col>
        </Row>
      </Container>
    </StyledConfirmation>
  );
};

const StyledConfirmation = styled.div`
  border-top: 1px solid ${lightGray};
  padding: ${quadSpace} ${doubleSpace};
  text-align: center;
`;

const StyledMessage = styled.div`
  background-color: white;
  border-radius: 10px;
  color: black;
  padding: ${doubleSpace};
  margin: 0 auto;

  ${md} {
    padding: ${tripleSpace};
    border-radius: 20px;
  }

  p {
    margin-top: ${doubleSpace};
  }

  button {
    margin-top: ${quadSpace};
  }
`;
