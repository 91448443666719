import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Open Sans', sans-serif;
  }
  html, body {
    height: 100%;
    width: 100%;
    background-color: white;
  }

  img {
    align-self: center;
  }
`;
