import React, { useEffect, useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { HttpClientContext } from "components/HttpClient";
import {
  ErrorMessage,
  SectionSubheader,
  doubleSpace,
  quadSpace,
  singleSpace,
} from "components/sharedComponents";
import { ShiftConfirmationCard } from "components/ShiftConfirmationCard";

export const ShiftListConfirmation = () => {
  const [confirmationSet, setConfirmationSet] = useState([]);
  const [error, setError] = useState(null);
  const { get } = useContext(HttpClientContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getConfirmationSet = async () => {
      setLoading(true);
      try {
        const res = await get({
          url: `confirmations/set/${id}/`,
        });
        setConfirmationSet(res["data"]["confirmations"]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    getConfirmationSet();
  }, [get, id]);

  if (!confirmationSet || confirmationSet.length === 0 || loading) {
    return (
      <StyledShiftListConfirmation>
        <Container>
          <Row>
            <SectionSubheader>Loading...</SectionSubheader>
          </Row>
        </Container>
      </StyledShiftListConfirmation>
    );
  }

  return (
    <StyledShiftListConfirmation>
      <Container>
        <Row>
          <Col lg={{ offset: 3, span: 6 }}>
            {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
            <SectionSubheader>
              Hi
              <span style={{ fontWeight: 700 }}>
                {" "}
                {confirmationSet[0].provider.first_name}!
              </span>{" "}
              It looks like you have {confirmationSet.length} shifts coming up
              tomorrow.
            </SectionSubheader>
            <SectionSubheader>
              Earn 200 reward points by confirming your shifts!
            </SectionSubheader>
          </Col>
        </Row>
        <Row>
          <Col lg={{ offset: 3, span: 6 }}>
            {confirmationSet.map((c) => (
              <Confirmation confirmation={c} key={c.id} />
            ))}
          </Col>
        </Row>
      </Container>
    </StyledShiftListConfirmation>
  );
};

const Confirmation = ({ confirmation }) => {
  const { post } = useContext(HttpClientContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submittedAction, setSubmittedAction] = useState(null);

  const submit = (value) => async () => {
    setLoading(true);
    try {
      await post({
        url: `confirmations/${confirmation.id}/`,
        payload: { action: value },
      });
      setSubmittedAction(value);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!confirmation) {
    return null;
  }

  return (
    <ShiftConfirmationCard
      submittedAction={submittedAction}
      confirmation={confirmation}
      error={error}
      loading={loading}
      submit={submit}
    />
  );
};

const StyledShiftListConfirmation = styled.div`
  padding: ${quadSpace} 0 ${doubleSpace};

  h4 {
    margin-bottom: ${doubleSpace};
    line-height: 32px;
  }

  p,
  li {
    margin-bottom: ${singleSpace};
  }
`;
