import React from "react";

import { Header } from "./components/Header";
import { CommsList } from "./components/CommsList";

export const CommsDashboard = (props) => {
  return (
    <div>
      <Header />
      <CommsList />
    </div>
  );
};
