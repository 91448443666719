import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import { HttpClientContext } from "components/HttpClient";
import {
  green,
  lightGray,
  red,
  orange,
  doubleSpace,
  Paragraph,
  singleSpace,
} from "components/sharedComponents";

import {
  MESSAGE_CLASSIFICATION,
  SHIFT_STATE,
} from "screens/ShiftDashboard/data/mockData";
import { formatDateTime } from "utils/dateTime";

import { MessageHistoryModal } from "./MessageHistoryModal";
import { CONFIRMATION_STATES, STATE_KEYWORD_MAP } from "./ConfirmationStates";

export const Shift = ({
  id,
  setError,
  state,
  start_date,
  end_date,
  provider,
  recipient,
  reminder_states,
}) => {
  const { post } = useContext(HttpClientContext);

  const [firstCheck, setFirstCheck] = useState(
    reminder_states["booking_confirmation"],
  );
  const [secondCheck, setSecondCheck] = useState(
    reminder_states["early_reminder"],
  );
  const [thirdCheck, setThirdCheck] = useState(
    reminder_states["day_before_reminder"],
  );
  const caregiverMessages = {
    classification: MESSAGE_CLASSIFICATION.default,
    messages: [],
  };

  const status = (() => {
    const toCheck = [firstCheck, secondCheck, thirdCheck];
    if (caregiverMessages.classification === MESSAGE_CLASSIFICATION.callout) {
      return SHIFT_STATE.cancelled;
    }

    if (toCheck.includes(STATE_KEYWORD_MAP.abandoned)) {
      return SHIFT_STATE.cancelled;
    }

    if (toCheck.includes(STATE_KEYWORD_MAP.timedOut)) {
      return SHIFT_STATE.atRisk;
    }

    return SHIFT_STATE.ok;
  })();

  const checkClassName = (toCheck) => {
    if (toCheck.includes("cancelled") || toCheck.includes("abandoned")) {
      return "cancelled";
    }

    if (toCheck.includes("timed_out")) {
      return "at-risk";
    }

    if (toCheck.includes("sent")) {
      return "ok";
    }

    if (toCheck.includes("confirmed")) {
      return "ok";
    }

    return "not-sent";
  };

  const updateShift = async (value) => {
    if (value === STATE_KEYWORD_MAP.notSent) {
      return;
    }
    try {
      await post({
        url: `shifts/${id}/`,
        payload: { update: value },
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const onChangeFirstCheck = (value) => {
    setFirstCheck(value);
    updateShift(value);
  };

  const onChangeSecondCheck = (value) => {
    setSecondCheck(value);
    updateShift(value);
  };

  const onChangeThirdCheck = (value) => {
    setThirdCheck(value);
    updateShift(value);
  };

  const { month, day, hours, minutes, period } = formatDateTime(start_date);
  const startDate = `${month} ${day}`;
  const startTime = `${hours}:${minutes} ${period}`;

  return (
    <StyledShiftItem>
      <Row>
        <Col xs={{ span: 1 }}>
          <StyledColumn
            className={
              status === SHIFT_STATE.ok
                ? "ok"
                : status === SHIFT_STATE.atRisk
                  ? "at-risk"
                  : "cancelled"
            }
          >
            <Paragraph>{status}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{`${provider.first_name} ${provider.last_name}`}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 2 }}>
          <StyledColumn>
            <Paragraph>{`${recipient.first_name} ${recipient.last_name}`}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <Paragraph>{startDate}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <Paragraph>{startTime}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={checkClassName(firstCheck)}>
            <CheckInState
              messages={[]}
              currentState={firstCheck}
              onChange={onChangeFirstCheck}
              options={CONFIRMATION_STATES}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={checkClassName(secondCheck)}>
            <CheckInState
              messages={[]}
              currentState={secondCheck}
              onChange={onChangeSecondCheck}
              options={CONFIRMATION_STATES}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn className={checkClassName(thirdCheck)}>
            <CheckInState
              messages={[]}
              currentState={thirdCheck}
              onChange={onChangeThirdCheck}
              options={CONFIRMATION_STATES}
            />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <CheckOutState date={end_date} messages={[]} time={end_date} />
          </StyledColumn>
        </Col>
        <Col xs={{ span: 1 }}>
          <StyledColumn>
            <CaregiverMessages messages={caregiverMessages} />
          </StyledColumn>
        </Col>
      </Row>
    </StyledShiftItem>
  );
};

const CheckInState = ({ currentState, messages, onChange, options }) => {
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };

  return (
    <div>
      <StyledDropdown>
        <select
          id="check-state"
          value={currentState}
          onChange={(event) => onChange(event.target.value)}
        >
          {Object.entries(options).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
      </StyledDropdown>
      <button onClick={() => setModal(true)}>View</button>
      <MessageHistoryModal
        isOpen={modal}
        messages={messages}
        onClose={closeModal}
      />
    </div>
  );
};

const CaregiverMessages = ({ messages }) => {
  const [modal, setModal] = useState(false);
  const label =
    messages.classification === MESSAGE_CLASSIFICATION.callout ? "Alert" : "OK";

  return (
    <div>
      <Paragraph
        className={
          messages.classification === MESSAGE_CLASSIFICATION.callout
            ? "alert"
            : ""
        }
      >
        {label}
      </Paragraph>
      <button onClick={() => setModal(true)}>View</button>
      <MessageHistoryModal
        isOpen={modal}
        messages={messages.messages}
        onClose={() => setModal(false)}
      />
    </div>
  );
};

const generateTimestamp = ({ date, time }) => {
  const [month, day] = date.split("/").map(Number);
  const [hourMinute, meridiem] = time.split(" ");
  let [hour, minute] = hourMinute.split(":").map(Number);

  if (meridiem === "PM" && hour !== 12) {
    hour += 12;
  } else if (meridiem === "AM" && hour === 12) {
    hour = 0;
  }

  const currentYear = new Date().getFullYear();

  const timestamp = new Date(currentYear, month - 1, day, hour, minute);

  return timestamp;
};

const CheckOutState = ({ date, messages, time }) => {
  const [modal, setModal] = useState(false);
  const scheduled = generateTimestamp({ date, time });
  const scheduledPlusOneHour = new Date(scheduled.getTime() + 60 * 60 * 1000);
  const now = new Date();

  if (now < scheduledPlusOneHour) {
    return <div></div>;
  }

  return (
    <div>
      <Paragraph style={{ fontSize: 16 }}>Not Sent</Paragraph>
      <button onClick={() => setModal(true)}>View</button>
      <MessageHistoryModal
        isOpen={modal}
        messages={messages}
        onClose={() => setModal(false)}
      />
    </div>
  );
};

export const StyledColumn = styled.div`
  padding-top: ${doubleSpace};
  padding-bottom: ${doubleSpace};
  text-align: left;

  p.alert {
    color: ${red};
  }

  &.cancelled {
    p,
    select {
      color: ${red};
      font-weight: bold;
    }
  }

  &.ok {
    p,
    select {
      color: ${green};
    }
  }

  &.at-risk {
    p,
    select {
      color: ${orange};
    }
  }

  &.not-sent {
    background-color: none;
  }

  button {
    border: none;
    background: none;
    display: inline-block;
    margin-top: ${singleSpace};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledShiftItem = styled.div`
  border-bottom: 1px solid ${lightGray};

  p {
    font-size: 16px;
  }
`;

const StyledDropdown = styled.div`
  select {
    background: none;
    padding: 5px;
    border-radius: 5px;
    border: none;
    max-width: 100%;
    font-weight: 600;
  }
`;
