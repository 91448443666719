import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  doubleSpace,
  tripleSpace,
  SectionHeader,
} from "components/sharedComponents";

export const Header = () => {
  return (
    <StyledHeader>
      <Container fluid>
        <Row>
          <Col xs={{ span: 12 }}>
            <SectionHeader>Priority SMS and Calls</SectionHeader>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  );
};

const StyledHeader = styled.section`
  margin: ${tripleSpace} ${doubleSpace};
`;
