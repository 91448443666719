import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  doubleSpace,
  quadSpace,
  md,
  SectionHeader,
  Paragraph,
  PrimaryButton,
  singleSpace,
} from "components/sharedComponents";
const heroImage = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/staff-1.jpg`;

const onClick = () => {
  const targetElement = document.getElementById("problem");
  if (targetElement) {
    targetElement.scrollIntoView({ block: "start", behavior: "instant" });
  }
};

export const Hero = () => {
  return (
    <StyledHero>
      <Container>
        <Row>
          <Col md={{ span: 7 }}>
            <SectionHeader>
              <ul>
                <li>Reduce overhead</li>
                <li>Improve care quality</li>
                <li>Eliminate employee burnout</li>
              </ul>
            </SectionHeader>
            <Paragraph>
              Sanctum is an intelligent assistant that manages and automates
              critical workflows for home care agencies.
            </Paragraph>
            <div className="d-flex">
              <PrimaryButton text="Learn more" onClick={onClick} />
            </div>
          </Col>
          <Col md={{ span: 5 }} style={{ textAlign: "center" }}>
            <img src={heroImage} alt="Customer" />
          </Col>
        </Row>
      </Container>
    </StyledHero>
  );
};

const StyledHero = styled.section`
  margin-bottom: ${quadSpace};

  li {
    font-size: 32px;
  }

  ul {
    padding: 0;
    margin-bottom: ${doubleSpace};

    li {
      list-style: none;
      margin-bottom: ${singleSpace};
    }
  }

  p {
    margin-bottom: ${doubleSpace};
  }

  img {
    max-width: 100%;
    border-radius: 10px;
  }

  button {
    margin-bottom: ${doubleSpace};
  }

  ${md} {
    margin: 60px 0 120px;

    li {
      font-size: 42px;
    }

    button {
      margin-bottom: 0;
    }
  }
`;
