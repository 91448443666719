import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useMatch } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

import { AuthContext } from "components/AuthContext";
import {
  doubleSpace,
  halfSpace,
  Paragraph,
  singleSpace,
  md,
  logoBlue,
} from "components/sharedComponents";
const logo = `${process.env.REACT_APP_ASSETS_URL}/img/logo.png`;

export const Header = () => {
  const { loggedIn } = useContext(AuthContext);

  return (
    <StyledHeader>
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={6} sm={3} md={2}>
            <Link to="/" className="logo">
              <StyledLogo className="d-flex">
                <img src={logo} alt="Sanctum logo" />
                <Paragraph>Sanctum</Paragraph>
              </StyledLogo>
            </Link>
          </Col>
          <Col
            className="d-none d-sm-flex flex-column align-items-end"
            sm={6}
            md={7}
          >
            <NavLinks loggedIn={loggedIn} />
          </Col>
          <Col xs={6} sm={3} md={{ span: 3 }}>
            <AuthLinks loggedIn={loggedIn} />
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  padding: 25px 0;
  a.logo {
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledLogo = styled.div`
  margin-left: 15px;
  align-items: center;
  p {
    color: ${logoBlue};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.03em;
    margin-left: 8px;
    text-transform: uppercase;
  }
  img {
    max-width: 50px;
  }
`;

const NavLinks = ({ loggedIn }) => {
  if (loggedIn) {
    return <StyledNavLinks className="align-items-end"></StyledNavLinks>;
  }
  return <StyledNavLinks></StyledNavLinks>;
};

const AuthLinks = ({ loggedIn }) => {
  if (loggedIn) {
    return (
      <StyledAuthLinks>
        <HighlightedLink to="/shift-dashboard" text="Dashboard" />
        <Link to="/logout">Logout</Link>
      </StyledAuthLinks>
    );
  }
  return (
    <StyledAuthLinks>
      <Link to="/signup">Signup</Link> <Link to="/login">Login</Link>{" "}
    </StyledAuthLinks>
  );
};

const HighlightedLink = ({ to, text }) => {
  const match = useMatch(":name");
  const isCurrentPage = to === `/${match?.params?.name}`;
  return (
    <Link to={to} className={isCurrentPage ? "highlighted" : ""}>
      {text}
    </Link>
  );
};

const StyledNavLinks = styled.div`
  align-items: end;
  display: flex;
  & > a {
    color: black;
    margin: 0 ${singleSpace};
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
  }

  .highlighted {
    text-decoration: underline;
  }

  ${md} {
    & > a {
      margin: 0 ${doubleSpace};
    }
  }
`;

const StyledAuthLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;

  & > a {
    color: black;
    margin: 0 ${halfSpace};
    width: 120px;
  }
`;
