export const SHIFT_STATE = {
  confirmed: "Confirmed",
  atRisk: "At Risk",
  cancelled: "Cancelled",
  ok: "OK",
};

export const STAFFING_MANAGERS = [
  "Aisha Patel",
  "Carlos Mendes",
  "Elena Ivanova",
  "Darnell Jackson",
  "Mei Ling Chen",
  "Rajesh Kapoor",
  "Amara Okafor",
  "Luca Rossi",
  "Sofia González",
  "Freya Nielsen",
];

export const CAREGIVERS = [
  "Alice Smith",
  "Bob Johnson",
  "Charlie Williams",
  "David Jones",
  "Eve Brown",
  "Frank Davis",
  "Grace Miller",
  "Heidi Wilson",
  "Ivan Moore",
  "Judy Taylor",
  "Mallory Anderson",
  "Niaj Thomas",
  "Olivia Jackson",
  "Peggy White",
  "Quentin Harris",
  "Rita Martin",
  "Sybil Thompson",
  "Trent Garcia",
  "Uma Martinez",
  "Victor Robinson",
];

export const PATIENTS = [
  "Margaret Walker",
  "Edward Bennett",
  "Doris Parker",
  "Harold Miller",
  "Gladys Thompson",
  "Albert Hayes",
  "Edith Roberts",
  "Walter Hughes",
  "Rose Armstrong",
  "George Harrison",
  "Betty Andrews",
  "Stanley Russell",
  "Lillian Reed",
  "Arthur Green",
  "Evelyn Price",
  "Frank Davis",
  "Martha Brooks",
  "Norman Webb",
  "Jeanette Moore",
  "Richard Cooper",
];

export const MESSAGE_CLASSIFICATION = {
  default: "default",
  callout: "callout",
};

const date = (daysOffset = 0) => {
  const today = new Date();
  today.setDate(today.getDate() + daysOffset);
  const month = today.getMonth() + 1;
  const day = today.getDate();

  return `${month}/${day}`;
};

const time = (minutesOffset = 0) => {
  const now = new Date();

  now.setMinutes(now.getMinutes() + minutesOffset);

  let hours = now.getHours();
  let minutes = now.getMinutes();

  if (minutes > 0 && minutes <= 30) {
    minutes = 30;
  } else if (minutes > 30) {
    minutes = 0;
    hours += 1;
  }

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // 0 hour should be 12
  const formattedMinutes = minutes === 0 ? "00" : "30";

  return `${hours}:${formattedMinutes} ${ampm}`;
};

const CALLOUT_THREAD = [
  {
    message: `Hi Sophia I'm sorry I can't make the shift today, my kid is sick.`,
    sender: "caregiver",
  },
  {
    message: `Sorry to hear it! Thanks for letting me know.`,
    sender: "office",
  },
];

export const SHIFTS = [
  {
    id: -1,
    caregiver: CAREGIVERS[19],
    patient: PATIENTS[19],
    manager: STAFFING_MANAGERS[9],
    date: date(),
    time: time(-90),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 0,
    caregiver: CAREGIVERS[18],
    patient: PATIENTS[18],
    manager: STAFFING_MANAGERS[8],
    date: date(),
    time: time(-90),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 1,
    caregiver: CAREGIVERS[0],
    patient: PATIENTS[0],
    manager: STAFFING_MANAGERS[0],
    date: date(),
    time: time(),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.callout,
      messages: CALLOUT_THREAD,
    },
  },
  {
    id: 2,
    caregiver: CAREGIVERS[1],
    patient: PATIENTS[1],
    manager: STAFFING_MANAGERS[1],
    date: date(),
    time: time(),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 3,
    caregiver: CAREGIVERS[2],
    patient: PATIENTS[2],
    manager: STAFFING_MANAGERS[2],

    date: date(),
    time: time(),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.callout,
      messages: CALLOUT_THREAD,
    },
  },
  {
    id: 4,
    caregiver: CAREGIVERS[3],
    patient: PATIENTS[3],
    manager: STAFFING_MANAGERS[3],
    date: date(),
    time: time(30),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 5,
    caregiver: CAREGIVERS[4],
    patient: PATIENTS[4],
    manager: STAFFING_MANAGERS[4],
    date: date(),
    time: time(30),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 6,
    caregiver: CAREGIVERS[5],
    patient: PATIENTS[5],
    manager: STAFFING_MANAGERS[5],
    date: date(),
    time: time(30),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 7,
    caregiver: CAREGIVERS[6],
    patient: PATIENTS[6],
    manager: STAFFING_MANAGERS[6],
    date: date(),
    time: time(60),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 8,
    caregiver: CAREGIVERS[7],
    patient: PATIENTS[7],
    manager: STAFFING_MANAGERS[7],
    date: date(),
    time: time(60),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 9,
    caregiver: CAREGIVERS[8],
    patient: PATIENTS[8],
    manager: STAFFING_MANAGERS[8],
    date: date(),
    time: time(60),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 10,
    caregiver: CAREGIVERS[9],
    patient: PATIENTS[9],
    manager: STAFFING_MANAGERS[9],
    date: date(),
    time: time(60),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 11,
    caregiver: CAREGIVERS[10],
    patient: PATIENTS[10],
    manager: STAFFING_MANAGERS[0],
    date: date(),
    time: time(60),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 12,
    caregiver: CAREGIVERS[11],
    patient: PATIENTS[11],
    manager: STAFFING_MANAGERS[1],
    date: date(),
    time: time(90),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 13,
    caregiver: CAREGIVERS[12],
    patient: PATIENTS[12],
    manager: STAFFING_MANAGERS[2],
    date: date(),
    time: time(90),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 14,
    caregiver: CAREGIVERS[13],
    patient: PATIENTS[13],
    manager: STAFFING_MANAGERS[3],
    date: date(),
    time: time(90),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 15,
    caregiver: CAREGIVERS[14],
    patient: PATIENTS[14],
    manager: STAFFING_MANAGERS[4],
    date: date(),
    time: time(120),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 16,
    caregiver: CAREGIVERS[15],
    patient: PATIENTS[15],
    manager: STAFFING_MANAGERS[5],
    date: date(),
    time: time(120),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
  {
    id: 17,
    caregiver: CAREGIVERS[16],
    patient: PATIENTS[16],
    manager: STAFFING_MANAGERS[6],
    date: date(),
    time: time(120),
    caregiverMessages: {
      classification: MESSAGE_CLASSIFICATION.default,
      messages: [],
    },
  },
];
