export const formatDateTime = (dateTimeString) => {
  // Expects dateTimeString in format 2024-04-11T16:00:00Z
  const date = new Date(dateTimeString);

  const options = { month: "long" };
  const month = new Intl.DateTimeFormat("en-US", options).format(date);
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensure 2 digits

  // Convert to 12-hour format
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;

  return {
    month,
    day,
    hours,
    minutes,
    period,
  };
};
