import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  doubleSpace,
  singleSpace,
  SectionSubheader,
} from "components/sharedComponents";

export const Header = ({
  showCalloutDashboard,
  showShiftList,
  visibleScreen,
}) => {
  return (
    <StyledHeader>
      <Row>
        <Col>
          <StyledButtonContainer>
            <button
              className={visibleScreen === "shiftList" ? "selected" : ""}
              onClick={showShiftList}
            >
              <SectionSubheader>All Shifts</SectionSubheader>
            </button>
            <button
              className={visibleScreen === "calloutDashboard" ? "selected" : ""}
              onClick={showCalloutDashboard}
            >
              <SectionSubheader>Callouts</SectionSubheader>
            </button>
          </StyledButtonContainer>
        </Col>
      </Row>
    </StyledHeader>
  );
};

const StyledHeader = styled.section`
  margin: ${singleSpace} 0 ${doubleSpace};

  button {
    background: none;
    padding: ${singleSpace};
    border: none;
    margin-right: 10px;

    &:hover,
    &.selected {
      text-decoration: underline;
    }

    h4 {
      font-weight: 700;
    }
  }
`;

const StyledButtonContainer = styled.div`
  padding-left: 2%;
`;
