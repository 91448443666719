import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SectionSubheader } from "components/sharedComponents";
import { formatDateTime } from "utils/dateTime";

export const Greeting = ({ confirmation }) => {
  const { month, day, hours, minutes, period } = formatDateTime(
    confirmation.shift.start_date,
  );

  return (
    <Row>
      <Col lg={{ offset: 3, span: 6 }}>
        <SectionSubheader>
          Hi{" "}
          <span style={{ fontWeight: 700 }}>
            {confirmation.provider.first_name}
          </span>
          ! It looks like you have a shift coming up on {month} {day} at {hours}
          :{minutes} {period}.
        </SectionSubheader>
        <SectionSubheader>
          Earn 200 reward points by confirming you'll be there!
        </SectionSubheader>
      </Col>
    </Row>
  );
};
