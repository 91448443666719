import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { quadSpace } from "components/sharedComponents";

import { AuthContext } from "components/AuthContext";

import { EmailCapture } from "components/EmailCapture";
import { Hero } from "./components/Hero";
import { ProblemStatement } from "./components/ProblemStatement";
import { Result } from "./components/Result";
import { Solution } from "./components/Solution";
import { Testimonials } from "./components/Testimonials";

export const HomePage = (props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/caregiver-status");
  }, [navigate, user]);

  return (
    <StyledHomePage>
      <Hero />
      <ProblemStatement />
      <Solution />
      <Testimonials />
      <Result />
      <EmailCapture headline={"Join the Waitlist"} category="inbox-demo-0.1" />
    </StyledHomePage>
  );
};

const StyledHomePage = styled.div`
  padding: ${quadSpace} 0;
`;
