import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthProvider } from "components/AuthContext";
import { Footer } from "components/Footer";
import { GlobalStyle } from "components/GlobalStyle";
import { Header } from "components/Header";
import { HttpClientProvider } from "components/HttpClient";
import { Logout } from "components/Logout";
import { PrivateRoute } from "components/PrivateRoute";
import { ScrollToTop } from "components/ScrollToTop.js";

import { CaregiverCallout } from "screens/CaregiverCallout/CaregiverCallout";
import { CommsDashboard } from "screens/CommsDashboard/CommsDashboard";
import { Contact } from "screens/Contact/Contact";
import { HomePage } from "screens/HomePage/HomePage";
import { LoginLink } from "screens/Login/LoginLink";
import { Login } from "screens/Login/Login";
import { NotFound } from "screens/NotFound";
import { PrivacyPolicy } from "screens/PrivacyPolicy";
import { Signup } from "screens/Signup/Signup";
import { SplashPage } from "screens/SplashPage/SplashPage";
import { ShiftConfirmation } from "screens/ShiftConfirmation/ShiftConfirmation";
import { ShiftDashboard } from "screens/ShiftDashboard/ShiftDashboard";
import { ShiftListConfirmation } from "screens/ShiftListConfirmation/ShiftListConfirmation";
import { TermsAndConditions } from "screens/TermsAndConditions";
import { Waitlist } from "screens/Waitlist/Waitlist";
import { WaitlistConfirmation } from "screens/WaitlistConfirmation/WaitlistConfirmation";

import { WithTracking } from "services/analytics";

function App() {
  const isProd = process.env.NODE_ENV === "production";

  return (
    <AuthProvider>
      <HttpClientProvider>
        <GlobalStyle />
        <Router>
          {isProd && <WithTracking />}
          <ScrollToTop />
          <Header />
          <Routes>
            {/* PUBLIC ROUTES */}
            <Route path="/" exact element={<SplashPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/homecare" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/link" element={<LoginLink />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/confirmation/:id" element={<ShiftConfirmation />} />
            <Route
              path="/confirmation-set/:id"
              element={<ShiftListConfirmation />}
            />
            <Route path="/waitlist" element={<Waitlist />} />
            {/* PRIVATE ROUTES */}
            <Route
              path="/caregiver-callout/:shiftId"
              element={
                <PrivateRoute>
                  <CaregiverCallout />
                </PrivateRoute>
              }
            />
            <Route
              path="/shift-dashboard"
              element={
                <PrivateRoute>
                  <ShiftDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <CommsDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route
              path="/waitlist-confirm"
              element={<WaitlistConfirmation />}
            />
            {/* 404 */}
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </HttpClientProvider>
    </AuthProvider>
  );
}

export default App;
