import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  quadSpace,
  Paragraph,
} from "components/sharedComponents";
import { ThreePartHeader } from "components/ThreePartHeader";

const problemImage = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/problem.png`;

export const ProblemStatement = () => {
  return (
    <StyledProblemStatement id="problem">
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <ThreePartHeader title="The Problem" />
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <Paragraph>
              Home care is a fast-moving business. Caregiver callouts, medical
              emergencies, patient assessments... your staff is fielding calls
              and texts and emails 24/7, trying to maintain quality of care
              while also avoiding burnout. There’s a constant tension between
              keeping overhead low, and maintaining care quality and employee
              satisfaction.
            </Paragraph>
            <img src={problemImage} alt="problem diagram"/>
          </Col>
        </Row>
      </Container>
    </StyledProblemStatement>
  );
};

const StyledProblemStatement = styled.div`
  margin-bottom: 120px;

  img {
    display: block;
    margin: 0 auto;
    max-width: 400px;
    margin-top: ${quadSpace};
    width: 100%;
  }
`;
