import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  greenOpaque,
  lightGray,
  redOpaque,
  doubleSpace,
  mediumGray,
  quadSpace,
  Paragraph,
  singleSpace,
} from "components/sharedComponents";

const STATE = ["Unresolved", "Resolved"];

const ASSIGNEES = [
  "Staffing Manager Jerry",
  "Staffing Manager Tom",
  "Care Manager Elizabeth",
  "Care Manager Victoria",
];

const items = [
  {
    sentAt: "1 min ago",
    category: "Caregiver Callout",
    assigned: ASSIGNEES[0],
    summary: "Jane Adams can’t make her shift that starts at 10:00 AM",
    currentState: STATE[0],
  },
  {
    sentAt: "37 min ago",
    category: "Client Reschedule",
    assigned: ASSIGNEES[3],
    summary: "Susan Anthony is cancelling her shift at 10:00 AM today",
    currentState: STATE[0],
  },
  {
    sentAt: "1 hour ago",
    category: "Caregiver Callout",
    assigned: ASSIGNEES[1],
    summary:
      "Cole James is sick and needs to cancel their 9:00 AM and 2:00 PM shifts today",
    currentState: STATE[1],
  },
  {
    sentAt: "3 hours ago",
    category: "Caregiver Callout",
    assigned: ASSIGNEES[0],
    summary:
      "Alex Draper needs to cancel their all-day shift starting at 10:00 AM",
    currentState: STATE[0],
  },
  {
    sentAt: "4 hours ago",
    category: "Hospital Discharge",
    assigned: ASSIGNEES[2],
    summary: "A new client was just discharged and needs care to start today",
    currentState: STATE[0],
  },
  {
    sentAt: "5 hours ago",
    category: "Client Reschedule",
    assigned: ASSIGNEES[3],
    summary: "James Vogl is cancelling his shift at 4:00 PM today",
    currentState: STATE[1],
  },
  {
    sentAt: "16 hours ago",
    category: "Medication Change",
    assigned: ASSIGNEES[3],
    summary:
      "George Steinman has a new prescription he needs to start taking tomorrow",
    currentState: STATE[0],
  },
  {
    sentAt: "22 hours ago",
    category: "Caregiver Callout",
    assigned: ASSIGNEES[1],
    summary: "Lawrence Ive can't make their shift at 8:00 PM",
    currentState: STATE[1],
  },
  {
    sentAt: "2 days ago",
    category: "Caregiver Callout",
    assigned: ASSIGNEES[1],
    summary: "Michelle Brown can't make their shift at 4:00 PM",
    currentState: STATE[1],
  },
  {
    sentAt: "2 days ago",
    category: "Hospital Discharge",
    assigned: ASSIGNEES[2],
    summary:
      "Jake Hilman is a potential new client, being discharged from the hospital at 5:00 PM",
    currentState: STATE[0],
  },
  {
    sentAt: "2 days ago",
    category: "Client Reschedule",
    assigned: ASSIGNEES[3],
    summary: "Belle Ang is cancelling her shift at 10:00 AM today",
    currentState: STATE[1],
  },
];

export const CommsList = () => {
  return (
    <StyledCommsList>
      <Container fluid>
        <Row className="header">
          <Col xs={{ span: 1 }}>
            <Paragraph>Sent</Paragraph>
          </Col>
          <Col xs={{ span: 2 }}>
            <Paragraph>Workflow</Paragraph>
          </Col>
          <Col xs={{ span: 3 }}>
            <Paragraph>Assigned</Paragraph>
          </Col>
          <Col xs={{ span: 4 }}>
            <Paragraph>Summary</Paragraph>
          </Col>
          <Col xs={{ span: 2 }}>
            <Paragraph>Status</Paragraph>
          </Col>
        </Row>
        {items.map((item, i) => (
          <CommsItem key={i} {...item} />
        ))}
      </Container>
    </StyledCommsList>
  );
};

const CommsItem = ({ sentAt, category, assigned, summary, currentState }) => {
  const assignedIndex = ASSIGNEES.indexOf(assigned);
  const stateIndex = STATE.indexOf(currentState);

  const [selectedAssignee, setSelectedAssignee] = useState(
    ASSIGNEES[assignedIndex],
  );
  const [itemState, setItemState] = useState(STATE[stateIndex]);

  const onChangeAssignee = (value) => {
    setSelectedAssignee(value);
  };

  const onChangeState = (event) => {
    console.log(event);
    setItemState(event.target.value);
  };

  return (
    <StyledCommsItem className={itemState === "Resolved" ? "resolved" : ""}>
      <Row>
        <Col xs={{ span: 1 }}>
          <Paragraph>{sentAt}</Paragraph>
        </Col>
        <Col xs={{ span: 2 }}>
          <Paragraph>{category}</Paragraph>
        </Col>
        <Col xs={{ span: 3 }}>
          <AssigneeDropdown
            assigned={selectedAssignee}
            onChangeAssignee={onChangeAssignee}
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Paragraph>{summary}</Paragraph>
        </Col>
        <Col xs={{ span: 2 }}>
          <StatusDropdown
            currentState={itemState}
            onChangeState={onChangeState}
          />
        </Col>
      </Row>
    </StyledCommsItem>
  );
};

const AssigneeDropdown = ({ assigned, onChangeAssignee }) => {
  return (
    <StyledDropdown>
      <select
        id="assignee-select"
        value={assigned}
        onChange={(event) => onChangeAssignee(event.target.value)}
      >
        {ASSIGNEES.map((assignee, index) => (
          <option key={index} value={assignee}>
            {assignee}
          </option>
        ))}
      </select>
    </StyledDropdown>
  );
};

const StatusDropdown = ({ currentState, onChangeState }) => {
  console.log(currentState);
  return (
    <StyledDropdown>
      <select id="state-select" value={currentState} onChange={onChangeState}>
        {STATE.map((s, index) => (
          <option key={index} value={s}>
            {s}
          </option>
        ))}
      </select>
    </StyledDropdown>
  );
};

const StyledCommsList = styled.section`
  .header {
    padding: ${doubleSpace};
    p {
      font-weight: 600;
    }
  }
`;

const StyledCommsItem = styled.div`
  background-color: ${redOpaque};
  padding: ${quadSpace} ${doubleSpace};
  border-bottom: 1px solid ${lightGray};

  &.resolved {
    background-color: ${greenOpaque};
  }
`;

const StyledDropdown = styled.div`
  select {
    padding: 5px ${singleSpace};
    border-radius: 5px;
    border: 1px solid ${mediumGray};
  }
`;
