import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AuthContext } from "components/AuthContext";

export const PrivateRoute = ({ children }) => {
  const { user, loadingAuth } = useContext(AuthContext);
  const location = useLocation();

  if (loadingAuth) return null;

  if (!user) {
    const redirect = location.pathname + location.search;
    const encodedRedirect = encodeURIComponent(redirect);

    const hasJobId = (url) => {
      const isSummary =
        url.includes("/summary") || url.includes("/organize-inbox");
      if (!isSummary) return null;
      const match = url.match(/\/[a-zA-Z-]+\/([a-zA-Z0-9-]+)/);
      return match ? match[1] : null;
    };

    const jobId = hasJobId(redirect);

    if (!jobId) return <Navigate to={`/login?redirect=${encodedRedirect}`} />;

    return (
      <Navigate
        to={`/login/link?job_id=${jobId}&redirect=${encodedRedirect}`}
      />
    );
  }

  return children;
};
