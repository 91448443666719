import React, { useEffect, useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { HttpClientContext } from "components/HttpClient";
import {
  singleSpace,
  doubleSpace,
  quadSpace,
} from "components/sharedComponents";
import { ShiftConfirmationCard } from "components/ShiftConfirmationCard";
import { Greeting } from "./components/Greeting";

export const ShiftConfirmation = () => {
  const { get, post } = useContext(HttpClientContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submittedAction, setSubmittedAction] = useState(null);
  const { id } = useParams();
  const [confirmation, setConfirmation] = useState(null);

  useEffect(() => {
    const getConfirmation = async () => {
      setLoading(true);
      try {
        const res = await get({
          url: `confirmations/${id}/`,
        });
        setConfirmation(res["data"]["confirmation"]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    getConfirmation();
  }, [get, id]);

  const submit = (value) => async () => {
    setLoading(true);
    try {
      await post({
        url: `confirmations/${id}/`,
        payload: { action: value },
      });
      setSubmitted(true);
      setSubmittedAction(value);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!confirmation) {
    return null;
  }

  return (
    <StyledShiftConfirmation>
      <Container>
        <Greeting confirmation={confirmation} />
        <ShiftConfirmationCard
          confirmation={confirmation}
          error={error}
          loading={loading}
          submit={submit}
          submitted={submitted}
          submittedAction={submittedAction}
        />
      </Container>
    </StyledShiftConfirmation>
  );
};

const StyledShiftConfirmation = styled.div`
  padding: ${quadSpace} 0 ${doubleSpace};

  h4 {
    margin-bottom: ${doubleSpace};
    line-height: 32px;
  }

  p,
  li {
    margin-bottom: ${singleSpace};
  }
`;
