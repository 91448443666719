import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

export const Contact = () => {
  return (
    <Container>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSclgnl5DgStvIlU7zm5RU1BVBieXbq2yf4HI--VyL6YeBJpuw/viewform?embedded=true"
            width="640"
            height="800"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Contact Form"
          >
            Loading…
          </iframe>
        </Col>
      </Row>
    </Container>
  );
};
